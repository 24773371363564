document.addEventListener("DOMContentLoaded", function(event) {

    document.getElementById('idMenuExit').addEventListener('click', function () {

        // Remove listener to re-enable scroll
        window.removeEventListener('scroll', noScroll);

        //Visibility Menu
        document.getElementById('idMenuHidden').style.opacity = '0';
        setTimeout(function () {
            document.getElementById('idMenuHidden').style.visibility = 'hidden';
        }, 1000);

        //Visibility Bouton Menu
        document.getElementById('idMenu').style.visibility = 'visible';
        document.getElementById('idMenu').style.opacity = '1';

        //Visibility Bouton Exit Menu
        document.getElementById('idMenuExit').style.visibility = 'hidden';
        document.getElementById('idMenuExit').style.opacity = '0';

    });

    document.getElementById('idMenu').addEventListener('click', function () {

        // add listener to disable scroll
        window.addEventListener('scroll', noScroll);

        //Visibility Menu
        document.getElementById('idMenuHidden').style.opacity = '1';
        document.getElementById('idMenuHidden').style.visibility = 'visible';

        //Visibility Bouton Menu
        document.getElementById('idMenu').style.visibility = 'hidden';
        document.getElementById('idMenu').style.opacity = '0';

        //Visibility Bouton Exit Menu
        document.getElementById('idMenuExit').style.visibility = 'visible';
        document.getElementById('idMenuExit').style.opacity = '1';

    });


    function noScroll() {
        window.scrollTo(0, 0);
    }

});

